.footer {
    background-color: black;
    color: white;
    bottom: 0;

    a {
        text-decoration: none;
        color: white;

        &:hover {
            color: #007bff;
        }
    }
}