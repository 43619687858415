.products-container {
    background-color: black;
    color: white;

    .separator {
        border-top: 3px solid white;
    }

    .selected-type {
        color: black;
        background-color: white;
    }

    .type {
        cursor: pointer;
        margin: 2px;
        &:hover {
            background-color: whitesmoke;
            color: black;
        };
        transition: all ease-in-out 0.1s
    }

    .card {
        max-width: 300px;
        min-height: 400px;
        img {
            height: 300px;
            object-fit: cover;
            object-position: center top;
        }
    }
    
    .card-title {
        min-height: 48px;
    }
}

.product-image {
    object-fit: cover;
    object-position: center top;
    width: 300px;
    height: 300px;
    border: 1px solid white;
    transition: 0.4s;
    &.with-description {
        opacity: 0.3 !important;
    }
}


.product-col {
    opacity: 1;
    cursor: zoom-in;
}

.product-description {
    width: 100%;
    bottom: 0;
    padding: 10px;
    z-index: 2;
    opacity: 1 !important;
}

.product-modal {
    top: 0;
    z-index: 1031;
    height: 100vh;
    width: 100vw;

    .backdrop {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background: black;
        left: 0;
    }

    img {
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        max-width: 90%;
        top: 5%;
        height: 90%;
        object-fit: contain;
    }

    .modal-description {
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 1%;
        max-height: 50%;
        z-index: 1;
        h1 {
            float: left;
        }
    }

    .close-modal {
        position: fixed;
        font-size: 50px;
        right: 20px;
        cursor: pointer;
        &:hover {
            color: red;
        }
        transition: all 0.3s;
    }

    .previous-image {
        position: fixed;
        font-size: 50px;
        top: 50%;
        left: 2%;
        cursor: pointer;
        z-index: 1;
        &:hover {
            color: lightblue;
        }
        transition: all 0.3s;
    }

    .next-image {
        position: fixed;
        font-size: 50px;
        top: 50%;
        right: 2%;
        cursor: pointer;
        z-index: 1;
        &:hover {
            color: lightblue;
        }
        transition: all 0.3s;
    }
}