.review-item {
    width: 90%;
    min-height: 150px;
}

.arrow {
    margin-top: 5%;
    cursor: pointer;
    &.text-muted {
        cursor: default;
    }
}

.circles {
    clear: both;
    .fa {
        margin: 3px;
    }
    i {
        cursor: pointer;
    }
}