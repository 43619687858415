$main-red: #ff0000;

#hero {
    padding-top: 70px;
    text-align: center;
    h1 {
        margin-top: 5vh;
    }
}

.about-us-btn {
    width: 200px;
    margin: auto;
}

.logo {
    max-width: 100%;
    max-height: 500px;
}

body {
    overflow-x: hidden;
}

.about {
    width: 80%;
    font-family: 'Open Sans', sans-serif;
}

body {
    font-family: 'Open Sans', sans-serif;
}

.about-us-btn {
    background: black !important;
    color: white !important;
}

.btn-danger {
    background-color: $main-red !important;
    border-color: $main-red !important;
}