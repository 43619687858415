h6 {
    cursor: pointer;
}

.answer {
    background-color: whitesmoke;
}

.answer-container {
    overflow: hidden;
}