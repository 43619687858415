.team-container {
    color: white;
    
    .separator {
        border-top: 3px solid white;
    }
}

.team-background {
    background: black;
}
