.about-us {
    background-color: black;
    color: white;

    img {
        max-width: 100%;
        border: 10px solid white;
    }

    .who {
        max-width: 600px;
        text-align: justify;
        margin: 10% auto;
        img {
            margin: 10px 0px;
        }
    }
}

.separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 3px solid black;
    width: 50px;
}

.about-us-container {
    background-color: black;
}

.image-carousel {
    img {
        max-width: 100%;
        max-height: 300px;
        border: 5px solid black;
    }
    overflow: hidden;
}

.about-footer {
    background-color: black;
    color: white;
}

.about-container {
    h1, h3 {
        font-family: "Open Sans", sans-serif;
    }
}