.stage-number {
    opacity: 0.2;
}

.stages-container {
    img {
        height: 500px;
        border: 1px solid black;
    }

    .model-description {
        position: absolute;
        left: -15px;
        width: 70%;
    }

    .mt-7 {
        margin-top: 7rem !important;
    }

    .model-description-mobile {
        margin: auto;
        max-width: 90%;
    }
}