.contact-container{
    form {
        background-color: whitesmoke;
        min-height: 420px;
    }

    .message-sent {
        background-color: whitesmoke;
        min-height: 420px;
        padding-top: 25% !important;
    }

    .desired-product {
        object-fit: cover;
        object-position: center top;
        width: 200px;
        height: 200px;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
        &.not-selected {
            opacity: 0.6;
        }
    }

    .fa {
        cursor: pointer;
    }
    
    textarea {
        height: 200px !important;
    }
}
